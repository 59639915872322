import React from 'react'
import times from 'lodash/times'
import styles from './review-stars.scss'
import {FaStar, FaRegStar} from 'react-icons/fa';


class ReviewStars extends React.Component {
  constructor (props) {
    super(props);
  }
  render() {
    let { stars, maxStars, color, reviewsNumber, productDescription, className } = this.props
    let absoluteStars = stars.toFixed(1).replace(/\.0$/, '')
    stars = stars ? Math.round(stars) : 0
    const emptyStars = maxStars - stars
    
    //Since the stars and the text will be the target element (and not the div), we need to add these attributes to each of the elements
    const dataAttributesForStars = {
      'data-event-trigger': 'click',
      'data-event-name': 'Reviews/Rating Clicked',
      'data-event-cta': 'Reviews Stars',
      'data-event-category': 'direct-link',
      'data-event-element': 'anchor',
      'data-event-uri': '#',
    };
    return (
      <div 
        title={`Review Score: ${stars} out of ${maxStars} stars`}
        className={`review-stars ${className} ${this.props.onClick && 'cursor-pointer'}`}
        onClick={ this.props.onClick || new Function } 
        data-reviews={reviewsNumber} 
        data-avg_score={stars} 
        data-max_score={maxStars}
        { ...dataAttributesForStars }>
        { times(stars, (i) => (
          <FaStar color={color} key={i} title="Filled Review Star Icon"/>
        )) }
        { times(emptyStars, (i) => (
          <FaRegStar color={color} key={i} title="Empty Review Star Icon"/>
        )) }
        { productDescription ? (
          typeof reviewsNumber === 'number' && (<span className='reviews-stars-reviews'><br className="d-md-none"/>Rated {absoluteStars} stars out of {reviewsNumber} Satisfied Customers</span>)
        ) :
          typeof reviewsNumber === 'number' && (<span className='reviews-stars-reviews'>{reviewsNumber} reviews</span>)
        }
      </div>
    )
  }
}

ReviewStars.defaultProps = {
  maxStars: 5,
  color: '#FB8E1E'
}

export default ReviewStars
